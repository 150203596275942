<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="CdkOverlayOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayPositions]="overlayPosition"
  [cdkConnectedOverlayPush]="true"
  (detach)="connectedOverlayDetach()">
  <div #dialog role="dialog" aria-label="Dialog" class="dialog-container">
    <ng-content></ng-content>
  </div>
</ng-template>
