import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy, OnInit,
  ViewChild,
} from "@angular/core";
import { CdkOverlayOrigin, ConnectionPositionPair } from "@angular/cdk/overlay";
import { debounceTime, filter, fromEvent, merge, share, startWith, Subject, switchMap,  takeUntil} from 'rxjs';
import { hoverPosition } from "@app/core/const/overlay.const";

@Component({
  standalone: false,
  selector: 'roam-hover',
  templateUrl: './roam-hover.component.html',
  styleUrls: ['./roam-hover.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoamHoverComponent implements OnInit, OnDestroy{

  @Input()
  public CdkOverlayOrigin!: CdkOverlayOrigin;

  @Input()
  public close: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public open: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public debounce: number = 100;

  @Input()
  public overlayPosition: ConnectionPositionPair[] = hoverPosition;

  @Input()
  public closeOnInsideClick: boolean = true; // New input property

  @ViewChild('dialog') dialog!: ElementRef;

  @ViewChild('menuTrigger') menu!: ElementRef; // Reference to the mat-menu

  public cdkOverlayOriginEl!: HTMLElement;
  public isOpened: boolean = false;

  private destroy$: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.cdkOverlayOriginEl = this.CdkOverlayOrigin.elementRef.nativeElement;
    this.showContent();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private showContent(): void {
    const CdkOverlayOriginEl = this.CdkOverlayOrigin.elementRef.nativeElement;

    const open$ = fromEvent(CdkOverlayOriginEl, 'mouseenter').pipe(
      switchMap(enterEvent =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          debounceTime(this.debounce),
          filter((event: any) => CdkOverlayOriginEl.contains(event['target']))
        )
      ),
      takeUntil(this.destroy$),
      share()
    );

    open$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.changeState(true);
      });

    const close$ = fromEvent(document, 'click').pipe(
      debounceTime(100),
      filter((event: any) => this.isOpened && !this.isClickedInside(CdkOverlayOriginEl, this.dialog, event))
    );

    const mouseLeave$ = fromEvent(document, 'mousemove').pipe(
      debounceTime(500),
      filter((event: any) => this.isOpened && !this.isMovedInside(CdkOverlayOriginEl, this.dialog, event))
    );

    merge(close$, mouseLeave$).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.cdr.detectChanges();
      this.changeState(false);
      this.isOpened = false;
    });
  }

  public connectedOverlayDetach() {
    this.changeState(false);
  }

  private changeState(isOpened: boolean) {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.cdr.markForCheck();
  }

  private isMovedOutside(CdkOverlayOriginEl: any, dialog: any, event: any): boolean {
    return !(CdkOverlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']) || this.isClickedInsideMenu(event));
  }

  private isClickedInside(CdkOverlayOriginEl: any, dialog: any, event: any): boolean {
    console.log(this.menu);
    
    return CdkOverlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']) || this.isClickedInsideMenu(event);
  }

  private isMovedInside(CdkOverlayOriginEl: any, dialog: any, event: any): boolean {
    return CdkOverlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']);
  }

  private isClickedInsideMenu(event: any): boolean {
    return this.menu && this.menu.nativeElement.contains(event['target']);
  }

}
