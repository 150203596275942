import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamHoverComponent } from './roam-hover/roam-hover.component';
import { OverlayModule } from "@angular/cdk/overlay";



@NgModule({
  declarations: [
    RoamHoverComponent
  ],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [
    RoamHoverComponent,
    OverlayModule
  ]
})
export class RoamHoverModule { }
